import { createRouter, createWebHistory } from "vue-router";
import {
  FileAddOutlined,
  FileSearchOutlined,
  LogoutOutlined,
  LockOutlined,
} from "@ant-design/icons-vue";

import store from "../store";
import {
  ADMIN_USER_PERMISSION,
  MODERATOR_USER_PERMISSION,
} from "../utils/Constants";

import { canAccess, hasPermission } from "./../utils/permissions";

export const routes = [
  {
    path: "/",
    visible: () => false,
    redirect: "/applications",
  },
  {
    path: "/applications",
    icon: <FileSearchOutlined />,
    visible: () => true,
    name: "Aplicações",
    component: () =>
      import(
        /* webpackChunkName: "applications" */ "../views/applications/Applications.vue"
      ),
  },
  {
    path: "/applications/:id",
    visible: () => false,
    name: "Processo",
    component: () =>
      import(
        /* webpackChunkName: "process" */ "../views/applications/Application.vue"
      ),
  },
  {
    path: "/applications/add",
    icon: <FileAddOutlined />,
    visible: () => true,
    name: "Nova aplicação",
    component: () =>
      import(
        /* webpackChunkName: "new-application" */ "../views/applications/Application.vue"
      ),
  },
  {
    path: "/login",
    visible: () => false,
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/update-password",
    icon: <LockOutlined />,
    visible: () => true,
    name: "Alterar Senha",
    component: () =>
      import(
        /* webpackChunkName: "update-password" */ "../views/user/UpdatePassword.vue"
      ),
  },
  {
    path: "/management",
    icon: <FileSearchOutlined />,
    visible: () => {
      return canAccess(store, [
        ADMIN_USER_PERMISSION,
        MODERATOR_USER_PERMISSION,
      ]);
    },
    name: "Gerência",
    component: () =>
      hasPermission(
        store,
        import(
          /* webpackChunkName: "management" */
          "../views/management/Management.vue"
        ),
        [ADMIN_USER_PERMISSION, MODERATOR_USER_PERMISSION]
      ),
  },
  {
    path: "/logout",
    name: "Sair",
    visible: () => true,
    icon: <LogoutOutlined />,
  },
  {
    path: "/recover-password",
    visible: () => false,
    name: "Recuperar Senha",
    component: () =>
      import(/* webpackChunkName: "recover" */ "../views/RecoverPassword.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/", "/recover-password"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = sessionStorage.getItem("access_token");

  if (to.path == "/login" && loggedIn) {
    next("/applications");
  }

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    // localStorage.removeItem("vuex");
    next("/login");
  } else {
    next();
  }
});

export default router;
